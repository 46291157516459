import React, { memo } from 'react';
import StyleGuide from '../StyleGuide';

const Header = ({ heading, subheading, isHorizontal, hasShadow }) => (
  <div
    className={`header ${isHorizontal ? 'horizontal' : 'vertical'} ${hasShadow ? 'shadow' : ''}`}
  >
    {heading && <h1 className="headline">{heading}</h1>}
    {subheading && <h1 className="headline sub-headline">{subheading}</h1>}
    <style jsx>
      {`
        div.shadow {
          box-shadow: ${StyleGuide.shadow};
        }
        div {
          position: relative;
          display: flex;
          height: 20px;
          padding: 10px 15px;
          background-color: ${StyleGuide.colors.backgrounds.header};
        }
        div.horizontal {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
        div.vertical {
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          min-height: 50px;
        }
        .headline {
          font-size: 1.2em;
          color: ${StyleGuide.colors.fonts.header};
          margin: 0;
        }
        .sub-headline {
          font-weight: 300;
          font-size: 1.2em;
          color: ${StyleGuide.colors.fonts.header};
          margin: 0;
        }
        @media (max-height: 799px) {
          div {
            height: 15px;
            padding-top: 5px;
            padding-bottom: 5px;
          }
        }
      `}
    </style>
  </div>
);

export default memo(Header);
