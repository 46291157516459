import React, { memo } from 'react';
import styled from 'styled-components';
import StyleGuide from '../StyleGuide';

const Button = styled.div`
  margin: 5px;
  border-width: 1px;
  border-color: ${StyleGuide.colors.backgrounds.button};
  border-radius: 5px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: ${({ disabled }) =>
    disabled ? StyleGuide.colors.backgrounds.buttonInverted : StyleGuide.colors.backgrounds.button};
  cursor: pointer;
  font-family: inherit;
  font-weight: bold;
  font-size: 1em;
  color: ${({ disabled }) =>
    disabled ? StyleGuide.colors.fonts.buttonInverted : StyleGuide.colors.fonts.button};
  :hover,
  :focus {
    opacity: 0.9;
    cursor: pointer;
  }
`;

const ButtonDiv = ({ disabled, text, onClick, tabIndex, ...props }) => (
  <Button
    {...props}
    disabled={disabled}
    role="button"
    tabIndex={tabIndex}
    onClick={() => !disabled && onClick && onClick()}
    onKeyPress={({ key }) => key === 'Enter' && !disabled && onClick && onClick()}
  >
    <span>{text}</span>
  </Button>
);

export default memo(ButtonDiv);
