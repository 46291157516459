import styled from 'styled-components';
import StyleGuide from '../StyleGuide';

const Button = styled.input.attrs(({ text }) => ({ type: 'submit', value: text }))`
  color: ${StyleGuide.colors.fonts.button};
  background-color: ${StyleGuide.colors.backgrounds.button};
  border-width: 0;
  border-radius: 5px;
  min-height: 30px;
  padding: 10px 20px;
  font-family: inherit;
  :hover {
    opacity: 0.9;
    cursor: pointer;
  }
  :focus {
    opacity: 0.9;
    cursor: pointer;
  }
`;

export default Button;
