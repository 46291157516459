import React from 'react';
import styled from 'styled-components';

const Outer = styled.div`
  position: relative;
  padding-top: ${({ ratio }) => `${ratio * 100}%`};
`;
const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AspectRatio = ({ className, ratio, children }) => (
  <Outer className={className} ratio={ratio}>
    <Inner>{children}</Inner>
  </Outer>
);

export default AspectRatio;
