import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import StyleGuide from '../StyleGuide';

const Overlay = styled.div`
  position: absolute;
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  top: calc(50% - 100px);
  left: calc(50% - 150px);
  background-color: ${StyleGuide.colors.backgrounds.darkTransparent};
  z-index: 999;
  border-radius: 10px;
  color: ${StyleGuide.colors.fonts.contrast};
  font-size: 1.5em;
`;

const ActionConfirm = ({ trigger, text }) => {
  const [show, setShow] = useState(false);
  const prevTrigger = useRef(trigger);
  useEffect(() => {
    let timeoutId = null;
    function hide() {
      setShow(false);
    }
    if (trigger && !prevTrigger.current) {
      setShow(true);
      timeoutId = setTimeout(hide, 1000);
    }
    prevTrigger.current = trigger;
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      hide();
    };
  }, [trigger]);

  if (!show) {
    return null;
  }
  return (
    <Overlay>
      <Icon name="check" size={60} />
      <h2>{text}</h2>
    </Overlay>
  );
};
export default ActionConfirm;
