import styled from 'styled-components';
import StyleGuide from '../StyleGuide';

const Title = styled.h3`
  margin: 15px 15px 10px;
  font-weight: bold;
  font-size: 1.2em;
  color: ${StyleGuide.colors.fonts};
`;

export default Title;
