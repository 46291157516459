import { memo } from 'react';
import styled from 'styled-components';
import TextInput from './TextInput';
import StyleGuide from '../StyleGuide';

const PasswordInput = styled(TextInput).attrs(({ succeeded, failed, title, setValue }) => ({
  wasSuccessful: !succeeded && !failed ? null : succeeded,
  type: 'password',
  placeholder: title,
  onChange: ({ target: { value: val } }) => setValue(val),
}))`
  margin: 10px;
  border-width: ${({ wasSuccessful }) => (wasSuccessful === null ? '0px' : '1px')};
  border-style: ${({ wasSuccessful }) => (wasSuccessful === null ? 'none' : 'solid')};
  border-color: ${({ wasSuccessful }) =>
    wasSuccessful ? StyleGuide.colors.fonts.success : StyleGuide.colors.fonts.signal};
  box-shadow: ${({ wasSuccessful }) =>
    wasSuccessful === null
      ? null
      : `0 0 2px 2px ${
          wasSuccessful ? StyleGuide.colors.fonts.success : StyleGuide.colors.fonts.signal
        };`};
`;

export default memo(PasswordInput);
