import styled from 'styled-components';

const TextInput = styled.input.attrs(({ isPassword, ...props }) => ({
  type: isPassword ? 'password' : 'text',
  ...props,
}))`
  border-width: 0;
  border-radius: 3px;
  font-family: inherit;
  font-size: 1em;
  padding: 10px;
`;

export default TextInput;
