import React from 'react';
import styled from 'styled-components';

const IconSpan = styled.span`
  font-size: ${({ size }) => (size ? `${size}px` : 'inherit')};
  color: ${({ color }) => color || 'inherit'};
`;

const Icon = ({ name, className, ...props }) => (
  <IconSpan className={`icon-${name} styled-icon ${className}`} {...props} />
);

export default Icon;
