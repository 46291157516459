import React, { useEffect, forwardRef, useRef, useCallback } from 'react';
import styled from 'styled-components';

const TextAreaComp = styled.textarea`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-width: 0;
  resize: none;
  border-radius: 3px;
  font-size: 1em;
  font-family: inherit;
  padding: 5px;
`;

const TextArea = ({ forwardedRef, noPaste = false, ...props }) => {
  const inputRef = useRef();
  const callbackRef = useCallback(ref => {
    inputRef.current = ref;
    if (typeof forwardedRef === 'function') {
      forwardedRef(ref);
    } else if (typeof forwardedRef === 'object' && forwardedRef) {
      forwardedRef.current = ref;
    }
  }, []);
  useEffect(() => {
    const preventPaste = e => {
      e.preventDefault();
    };
    if (inputRef.current && noPaste) {
      inputRef.current.addEventListener('paste', preventPaste);
      return () => inputRef.current.removeEventListener('paste', preventPaste);
    }
    return undefined;
  }, [noPaste]);

  return <TextAreaComp {...props} ref={callbackRef} />;
};

export default forwardRef((props, ref) => <TextArea forwardedRef={ref} {...props} />);
