import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ children }) => {
  const element = document.getElementById('portal');
  if (!element) {
    return null;
  }
  return ReactDOM.createPortal(children, element);
};

export default Modal;
