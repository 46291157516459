import React, { memo } from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import StyleGuide from '../StyleGuide';
import { withTranslation } from '../i18n';

const AbsoluteDiv = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const GalleryPic = styled.div`
  position: relative;
`;

const AbsoluteImage = (props) => <AbsoluteDiv as="img" {...props} />;
const Image = styled(AbsoluteImage)`
  width: 100%;
  height: 100%;
`;

const LockedOverlay = styled(AbsoluteDiv)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: ${({ size }) => (size === 'large' ? '1.2em' : '1em')};
  padding: ${({ size }) => (size === 'large' ? '10px' : '0px')};
`;

const LockInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${StyleGuide.colors.backgrounds.darkTransparent};
  flex: ${({ size }) => (size === 'large' ? 0 : 1)};
  align-self: ${({ size }) => (size === 'large' ? 'center' : 'stretch')};
  min-width: 100px;
  min-height: 100px;
`;

const UnlockedOverlay = styled(AbsoluteDiv)`
  left: auto;
  top: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: ${StyleGuide.colors.fonts.contrast};
  border-radius: 10px;
  background-color: ${StyleGuide.colors.backgrounds.darkTransparent};
  font-size: ${({ size }) => (size === 'large' ? '1.2em' : '0.8em')};
  padding: ${({ size }) => (size === 'large' ? '10px' : '5px')};
`;

const OverlayText = styled.span`
  margin-top: 5px;
  font-size: 1.2em;
  color: ${StyleGuide.colors.fonts.light};
`;

const GalleryImage = ({
  url,
  price,
  isBillable,
  isUnlocked,
  onClick,
  t,
  size = 'small',
  className,
}) => {
  const iconSize = (() => {
    switch (size) {
      case 'small':
        return 35;
      case 'large':
        return 50;
      default:
        return 40;
    }
  })();
  return (
    <GalleryPic role="presentation" className={className} onClick={() => onClick && onClick()}>
      <Image className={className} src={url} alt="Gallery" />
      {!isUnlocked && (
        <LockedOverlay>
          <Icon
            name={isBillable ? 'locked' : 'friends'}
            size={iconSize}
            color={StyleGuide.colors.icons.light}
          />
          {price !== 0 && price && size !== 'small' && (
            <OverlayText>{t('common:coinsAmount', { count: price })}</OverlayText>
          )}
        </LockedOverlay>
      )}
      {isUnlocked && isBillable && size !== 'small' && (
        <UnlockedOverlay>
          <Icon name="unlocked" size={10} color={StyleGuide.colors.icons.white} />
          <span>{t('common:coinsAmount', { count: price })}</span>
        </UnlockedOverlay>
      )}
      <style jsx>
        {`
          .gallery-image {
            position: relative;
          }
          .absolute {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          }
          img {
            width: 100%;
            height: 100%;
          }
          .lock-overlay {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .lock-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: ${StyleGuide.colors.backgrounds.darkTransparent};
            flex: 1;
            align-self: stretch;
          }
          .large .lock-container {
            padding: 5px;
            border-radius: 5px;
            align-self: center;
            flex: 0;
            min-width: 100px;
            min-height: 100px;
            background-color: ${StyleGuide.colors.backgrounds.darkOverlay};
          }
          .unlock-overlay {
            left: auto;
            top: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            color: ${StyleGuide.colors.fonts.contrast};
            padding: 5px;
            border-radius: 10px;
            background-color: ${StyleGuide.colors.backgrounds.darkTransparent};
            font-size: 0.8em;
          }
          .large {
            font-size: 1.2em;
            padding: 10px;
          }
        `}
      </style>
    </GalleryPic>
  );
};

export default withTranslation(['conversation', 'common'])(memo(GalleryImage));
