import React, { memo } from 'react';
import { Waypoint } from 'react-waypoint';

const InfiniteScroll = ({ threshold = 250, onThresholdReached, children, className }) => {
  return (
    <div className={className}>
      {children}
      <Waypoint onEnter={onThresholdReached} topOffset={threshold} />
    </div>
  );
};

export default memo(InfiniteScroll);
