import React from 'react';
import styled from 'styled-components';
import Modal from './Modal';
import StyleGuide from '../StyleGuide';

const Background = styled.div`
  background-color: ${StyleGuide.colors.backgrounds.darkTransparent};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;

const DialogView = styled.div`
  background-color: ${StyleGuide.colors.backgrounds.white};
  display: flex;
  align-items: stretch;
  flex-direction: column;
  max-width: 700px;
  min-height: 200px;
  max-height: 50%;
  position: relative;
`;

const ButtonView = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 20px;
`;

const Button = styled.button`
  border-radius: 5px;
  padding: 0 30px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border: 1px solid
    ${({ disabled }) =>
      disabled ? StyleGuide.colors.backgrounds.dark : StyleGuide.colors.backgrounds.button};
  background-color: ${({ isFocused, disabled }) => {
    if (disabled) {
      return StyleGuide.colors.backgrounds.dark;
    }
    return isFocused
      ? StyleGuide.colors.backgrounds.button
      : StyleGuide.colors.backgrounds.buttonInverted;
  }};
  color: ${({ isFocused, disabled }) =>
    isFocused || disabled
      ? StyleGuide.colors.fonts.button
      : StyleGuide.colors.fonts.buttonInverted};
  letter-spacing: 0.1em;
  font-weight: 300;
  margin: 0 5px;
  cursor: pointer;
  :last-child {
    margin-right: 0;
  }
  :first-child {
    margin-left: 0;
  }
  :not(:disabled):hover:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background-color: ${StyleGuide.colors.backgrounds.lightTransparent};
  }
`;

const TitleView = styled.div`
  border-bottom: 1px solid ${StyleGuide.colors.backgrounds.button};
  padding: 20px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 1em;
`;

const Text = styled.p`
  padding: 0 20px;
  white-space: pre;
`;

const ChildrenView = styled.div`
  align-self: stretch;
  flex-basis: 0;
  flex: 1;
  max-height: 90%;
  display: contents;
  position: relative;
`;

const Dialog = ({ children, buttons = [], title, text, className }) => {
  return (
    <Modal>
      <Background>
        <DialogView className={className}>
          {!!title && (
            <TitleView>
              <Title>{title}</Title>
            </TitleView>
          )}
          {!!text && <Text>{text}</Text>}
          <ChildrenView>{children}</ChildrenView>
          <ButtonView>
            {buttons.map(({ text: buttonText, action, isFocused, disabled }) => (
              <Button
                isFocused={isFocused}
                onClick={(e) => {
                  e.preventDefault();
                  action();
                }}
                disabled={disabled}
              >
                <span>{buttonText}</span>
              </Button>
            ))}
          </ButtonView>
        </DialogView>
      </Background>
    </Modal>
  );
};

export default Dialog;
