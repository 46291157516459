import React, { memo, useEffect, useRef } from 'react';

const SignalAudio = ({ source, observedValue }) => {
  const audioElementRef = useRef(null);
  useEffect(() => {
    function play() {
      audioElementRef.current.play();
    }
    if (observedValue) {
      audioElementRef.current.addEventListener('canplaythrough', play);
      audioElementRef.current.load();
      return () => audioElementRef.current.removeEventListener('canplaythrough', play);
    }
    return undefined;
  }, [observedValue, audioElementRef]);
  return <audio src={source} ref={audioElementRef} />;
};

export default memo(SignalAudio);
