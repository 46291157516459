export default {
  colors: {
    backgrounds: {
      light: '#D8D8D8',
      dark: '#C8C8C8',
      black: '#202020',
      colored: '#4A90E2',
      headerButton: '#DD5D0F',
      button: '#4A90E2',
      buttonOverlay: 'rgba(255,255,255, 0.1)',
      buttonInverted: '#FFFFFF',
      myMessage: '#4A90E2',
      otherMessage: '#FFFFFF',
      header: '#FFFFFF',
      lightTransparent: 'rgba(216,216,216, 0.5)',
      darkTransparent: 'rgba(0,0,0, 0.7)',
      overlay: 'rgba(255,255,255,0.5)',
      queueItem: '#FFFFFF',
      white: '#FFFFFF',
      galleryButton: '#DD5D0F',
      male: '#68B2F1',
      female: '#FB89FF',
      alert: 'red',
      friendship: '#F57E23',
      unlock: '#D1E7F9',
      darkOverlay: 'rgba(0,0,0, 0.3)',
      overlayBackground: '#4A4A4A',
      darkHeader: '#9B9B9B',
      login: '#333333',
      input: '#222222',
    },
    fonts: {
      myMessage: '#FFFFFF',
      otherMessage: '#000000',
      button: '#FFFFFF',
      buttonInverted: '#4A90E2',
      header: '#000000',
      title: '#000000',
      contrast: '#FFFFFF',
      signal: 'red',
      success: 'green',
      friendship: '#F57E23',
      online: 'green',
      offline: 'red',
      light: '#C8C8C8',
      white: '#FFFFFF',
      dark: '#4A4A4A',
      alert: '#D43F3A',
    },
    borders: {
      logout: '#D43F3A',
      imageHighlight: '#DD5D0F',
      black: '#000',
      white: '#FFF',
      dark: '#C8C8C8',
    },
    icons: {
      login: '#FFFFFF',
      alert: 'red',
      header: '#FFFFFF',
      dark: '#4A4A4A',
      blue: '#4A90E2',
      white: '#FFFFFF',
      whiteTransparent: 'rgba(255,255,255,0.7)',
      light: '#C8C8C8',
      statistics: '#DD5D0F',
      coins: '#F5A623',
    },
    statistics: {
      red: '#BE1E1E',
      yellow: '#EFDD11',
      green: '#40C432',
    },
  },
  shadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.5)',
  save: '0 0 10px #7ed321',
  imageHighlight: ' 0 0 10px 0 #DD5D0F',
};
