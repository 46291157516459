import React from 'react';
import Head from 'next/head';
import { withTranslation } from '../i18n';

const SiteTitle = ({ t, text }) => (
  <Head>
    <title>{text || t('title')}</title>
  </Head>
);

export default withTranslation('common')(SiteTitle);
